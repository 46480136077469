import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, keywords }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultKeywords,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || defaultKeywords,
  }

  return (
    <Helmet title={seo.title}>
      <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="" />
      <link rel="preconnect" href="https://googleads.g.doubleclick.net" crossOrigin="" />
      <link rel="preconnect" href="https://www.googleadservices.com" crossOrigin="" />
      <link rel="preconnect" href="https://www.google.com" crossOrigin="" />
      <link rel="preconnect" href="https://www.google.com.br" crossOrigin="" />
      <link rel="preconnect" href="https://www.facebook.com" crossOrigin="" />
      <link rel="preconnect" href="https://ajax.googleapis.com" crossOrigin="" />
      <link rel="preconnect" href="https://pages.rdstation.com.br" crossOrigin="" />

      <meta charset="utf-8"/>
      <meta http-equiv="x-ua-compatible" content="ie=edge"/>

      <meta property="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />

      <meta property="og:site_name" content={seo.title} />
      <meta property="og:type" content='website' />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image} />

      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
      <script type="application/ld+json" src='json/ld.json'></script>
      <meta name="facebook-domain-verification" content="c9e18ioh3hi0zo0vvyh86yfwds48t8" />
      <link rel="stylesheet" href="devices.min.css" type="text/css"/>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  keywords: null
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image,
        defaultKeywords: keywords
      }
    }
  }
`